import { render, staticRenderFns } from "./CouponSettings.vue?vue&type=template&id=25e27f33&scoped=true&lang=pug&"
import script from "./CouponSettings.vue?vue&type=script&lang=js&"
export * from "./CouponSettings.vue?vue&type=script&lang=js&"
import style0 from "./CouponSettings.vue?vue&type=style&index=0&id=25e27f33&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25e27f33",
  null
  
)

export default component.exports