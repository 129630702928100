<template lang="pug">
om-modal(name="coupon-settings" :width="680" color="light" @beforeOpen="beforeOpen")
  template(slot="modal-header")
    .row
      .col
        .font-weight-bold.font-size-1--25 {{ $t('coupon') }}
    .brand-modal-action-icon
      span.cursor-pointer(@click="$modal.hide('coupon-settings')" title="close" aria-hidden="true")
        close-icon(:width="12" :height="12")
  template(slot="modal-body")
    .d-flex.flex-column
      .col
        .row.align-items-center
          span.small-alt-title {{ $t('couponCode') }}
          textarea.w-100(
            rows="5"
            v-model="coupons"
            :placeholder="$t('couponPlaceholder')"
            :disabled="isDisabled"
          )
          span.small-alt-title {{ $t('separateCoupons') }}
          om-button.d-flex.justify-content-end.pt-2(
            @click="resetCoupons"
            :disabled="isDisabled"
            :class="{ loading: reset }"
          ) {{ $t('resetCoupons') }}
      .col.mt-3
        .row.align-items-center
          input#couponInput(
            type="file"
            :accept="getAcceptedExtension(true)"
            ref="file"
            style="display: none"
            @change="fileChange"
          )
          om-button(secondary @click="$refs.file.click()") {{ $t('couponModal.fileUpload') }}
          span.small-alt-title.ml-2 {{ fileName }}
        .row.align-items-center
          span.small-alt-title {{ $t('couponModal.formats') }} {{ getAcceptedExtension(false) }}
  template(slot="modal-footer")
    .d-flex.justify-content-end
      om-button.mr-3(ghost @click="hideModal") {{ $t('cancel') }}
      om-button(primary @click="uploadCoupons" :disabled="isDisabled" :loading="processing") {{ $t('add') }}
</template>
<script>
  import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
  import GET_AVAILABLE_COUPONS from '@/graphql/GetAvailableCoupons.gql';
  import UPLOAD_COUPONS from '@/graphql/UploadCoupons.gql';
  import RESET_COUPONS from '@/graphql/ResetCoupons.gql';
  import { getBrandedClassString } from '@/components/Elements/Button';

  const ACCEPTED_EXTENSION = ['.txt', '.csv'];
  const strToArray = (str) => str.split(/[\s,]+/).map((c) => c.trim());

  export default {
    data() {
      return {
        reset: false,
        processing: false,
        fileRead: false,
        coupons: '',
        fileName: null,
      };
    },
    computed: {
      ...mapState(['selectedElement', 'showFormManager']),
      ...mapGetters(['campaignInnerId']),
      couponArray() {
        return strToArray(this.coupons);
      },
      isDisabled() {
        return this.reset || this.processing || this.fileRead;
      },
    },
    methods: {
      ...mapActions(['fetchCouponCounts']),
      ...mapMutations(['changeFormManagerVisibility']),
      async uploadCoupons() {
        if (this.isDisabled) return;

        this.processing = true;
        try {
          await this.$apollo.mutate({
            mutation: UPLOAD_COUPONS,
            variables: {
              input: { coupons: this.couponArray, campaignId: this.campaignInnerId },
            },
          });

          await this.fetchCouponCounts();
          this.updateCoupons();

          this.processing = false;
        } catch (e) {
          this.processing = false;
        }

        this.hideModal();
      },
      fileChange(e) {
        this.fileName = e.target.files[0].name;
        this.fileRead = true;
        const files = e.target.files || e.dataTransfer.files;
        if (!files.length) {
          return;
        }

        if (files[0].size > 2 * 1024 * 1024) {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.fileSizeLimit'),
          });
          this.fileRead = false;
          return;
        }

        setTimeout(() => {
          const reader = new FileReader();
          reader.readAsText(files[0]);
          reader.onload = (e) => {
            const result = e.target.result;
            if (result) {
              this.coupons = this.couponArray.concat(strToArray(result)).join(', ');
              document.getElementById('couponInput').value = '';
            }
            this.fileRead = false;
          };
        }, 500);
      },
      hideModal() {
        if (this.isDisabled) return;

        this.$modal.hide('coupon-settings');
      },
      resetCoupons() {
        if (this.isDisabled) return;

        this.$modal.show('dialog', {
          text: this.$t('couponResetWarning'),
          buttons: [
            {
              title: this.$t('resetCoupons'),
              class: getBrandedClassString({ primary: true }, 'mr-3'),
              handler: async () => {
                this.reset = true;

                const success = await this.$apollo.mutate({
                  mutation: RESET_COUPONS,
                  variables: {
                    campaignId: this.campaignInnerId,
                  },
                });

                if (success) {
                  this.coupons = '';
                  this.updateCoupons();
                }

                await this.fetchCouponCounts();

                this.reset = false;
                this.$modal.hide('dialog');
              },
            },
            {
              title: this.$t('cancel'),
              default: true,
              class: getBrandedClassString({ secondary: true }),
            },
          ],
        });
      },
      async beforeOpen() {
        try {
          const { data } = await this.$apollo.query({
            query: GET_AVAILABLE_COUPONS,
            variables: {
              campaignId: this.campaignInnerId,
            },
          });
          this.coupons = data.coupons.join(', ');
        } catch (error) {
          console.error('[COUPON] Error occured in "beforeOpen"', error);
        }
      },
      getAcceptedExtension(fileFormat) {
        return fileFormat
          ? ACCEPTED_EXTENSION.join(',')
          : ACCEPTED_EXTENSION.map((extension) => extension).join(', ');
      },
      updateCoupons() {
        this.$store.commit('updateData', {
          property: 'selectedElement.data.coupon',
          value: this.selectedElement.data.coupon,
        });
      },
    },
  };
</script>
<style lang="sass" scoped>
  .small-alt-title
    font-family: Roboto
    font-style: normal
    font-weight: normal
    font-size: 12px
    line-height: 24px
    color: #8F97A4
</style>
